$mdc-typography-styles-headline1: (  font-family: unquote("Montserrat"),  font-size: 97);$mdc-typography-styles-headline2: (  font-family: unquote("Montserrat"),  font-size: 61);$mdc-typography-styles-headline3: (  font-family: unquote("Montserrat"),  font-size: 48);$mdc-typography-styles-headline4: (  font-family: unquote("Montserrat"),  font-size: 34);$mdc-typography-styles-headline5: (  font-family: unquote("Montserrat"),  font-size: 24);$mdc-typography-styles-headline6: (  font-family: unquote("Montserrat"),  font-size: 20);$mdc-typography-styles-body1: (  font-family: unquote("Montserrat"),  font-size: 16);$mdc-typography-styles-body2: (  font-family: unquote("Montserrat"),  font-size: 14);$mdc-typography-styles-subtitle1: (  font-family: unquote("Montserrat"),  font-size: 16);$mdc-typography-styles-subtitle2: (  font-family: unquote("Montserrat"),  font-size: 14);$mdc-typography-styles-button: (  font-family: unquote("Montserrat"),  font-size: 14);$mdc-typography-styles-overline: (  font-family: unquote("Montserrat"),  font-size: 10);$mdc-typography-styles-caption: (  font-family: unquote("Montserrat"),  font-size: 12);


.gradienttext {
    background-color: #ff4081;
    background-image: linear-gradient(45deg, #ff4081, #af4261);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;

   
    font-weight: normal;
    font-size: 6em;
    text-align: center;
    margin-bottom: 0;
    margin-bottom: -0.25em;
  }



.mainHeroBackground{

    background: linear-gradient(0deg, #e91e63 30%, #fff 30%);
}
.mainHeroBackgroundInverted{

  background: linear-gradient(0deg, #00b0ff 30%, #424242 30%);
}

.cta{
  background: #3d5afe ;
  color: #fff;
}
.cta:hover{
  background: #e91e63 ;
  color: #fff;
}

.productImages{
  background: #00b0ff ;
  color: #fff;
  

}

.hs-button{
  font-family:"Montserrat","Roboto","Helvetica","Arial",sans-serif;
  text-transform:uppercase;
  font-weight:500;
}
